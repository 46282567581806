<template>
  <v-card-text class="px-0">
    <v-form ref="formularioBusquedaSociedad" v-model="valid" lazy-validation autocomplete="on">
      <v-layout row wrap :mx-4="this.$vuetify.breakpoint.width<960">
          <v-flex lg1 class="hidden-xs-and-down">
              
          </v-flex>
        <v-flex lg4 md5 xs12>
          <v-text-field
            label="Nombre Sociedad Civil"
            name="nombreSociedad"
            :rules="sociedad.nombre!=null?ruleNombre:[]"
            v-model="sociedad.nombre"
            prepend-icon="mdi-account-group"
          ></v-text-field>
        </v-flex>
        <v-flex lg4 md5 xs12>
          <v-select
            :items="departamentos"
            v-model="sociedad.departamento"
            label="Departamento"
            prepend-icon="mdi-map-marker"
            dense
          ></v-select>
        </v-flex>
        <div id="panelResultadosSociedad"></div>
        <v-flex lg2 md2 xs12>
          <v-btn
            color="primary lighten-2"
            :loading="peticion"
            @click="busqueda()"
            :disabled="!valid"
          >buscar</v-btn>
          <v-btn v-if="solicitud" color="error" @click="limpiarBusqueda()">Limpiar Busqueda</v-btn>
        </v-flex>
      </v-layout>
    </v-form>
    <div v-if="items">
    <v-data-table 
        v-if="items.length>0"
      :headers="headers"
      :items="items"
      :total-items="totalItemsSociedad"
      hide-actions
      class="elevation-0 px-0"
      :pagination.sync="pagination"
      no-data-text="Sin Datos"
      :loading="peticion"
    >
      <template slot="items" slot-scope="props">
        <tr >
            <td>
                <v-icon @click="mostrarInfoAbogado(props.item)">info</v-icon>
            </td>
          <td class="text-xs-left">{{props.item.nombreSociedad}}</td>
          <td class="text-xs-left">{{props.item.departamento}}</td>
          <td class="text-xs-left">{{props.item.matricula}}</td>
          <td class="text-xs-left">{{formatDate(props.item.fechaResolucion)}}</td>
        </tr>
      </template>
    </v-data-table>
    </div>
  </v-card-text>
</template>
<script>
export default {
  data() {
    return {
      departamentos: [
        { value: 27, text: "LA PAZ" },
        { value: 33, text: "SANTA CRUZ" },
        { value: 26, text: "COCHABAMBA" },
        { value: 25, text: "CHUQUISACA" },
        { value: 29, text: "ORURO" },
        { value: 34, text: "TARIJA" },
        { value: 24, text: "BENI" },
        { value: 31, text: "PANDO" },
        { value: 32, text: "POTOSI" }
      ],
      sociedad: {
        nombre: null,
        departamento: null
      },
      valid: true,
      peticion: null,
      solicitud: false,
      ruleNombre: [
        v => (v && v.length >= 4) || "El campo debe tener al menos 4 caracteres"
      ],
      pagination: {},
      items: [],
      totalItemsSociedad: 0,
      headers: [
        {
          text: "",
          value: "actions",
          align: "left",
          sortable: false
        },
        {
          text: "Nombre Sociedad",
          value: "nombreSociedad",
          align: "left",
          sortable: false
        },
        {
          text: "Departameto",
          value: "departamento",
          align: "left",
          sortable: false
        },
        {
          text: "Matricula",
          value: "matricula",
          align: "left",
          sortable: false
        },
        {
          text: "Fecha Resolucion",
          value: "fechaResolucion",
          align: "left",
          sortable: false 
        }
      ]
    };
  },watch: {
    pagination: {
      handler() {
        if (this.solicitud) {
          this.$vuetify.goTo("#panelResultadosSociedad", {});
          this.busqueda();
        }
      },
      deep: true
    }
  },
  methods: {
    busqueda() {
      if (this.$refs.formularioBusquedaSociedad.validate()) {
        if (this.sociedad.nombre!=null || this.sociedad.departamento!=null) {
          var config = {
            params: {
              nombreSociedad: this.sociedad.nombre,
              departamento: this.sociedad.departamento,
              page: this.pagination.page
            }
          };
          this.peticion = true;
          this.$http
            .get(`${this.$apiUrl}buscar-sociedad`, config)
            .then(response => {
              this.peticion = false;
              this.solicitud=true;
              this.items = response.data.data.items;
              this.totalItemsSociedad = response.data.data.total_count;
              if (this.totalItemsSociedad == 0) {
                this.$store.commit(
                  "setError",
                  "Sin Resultados para la busqueda"
                );
              } else {
                this.resp = true;
                this.$vuetify.goTo("#panelResultadosSociedad", {});
              }
            })
            .catch(error => {
              this.resp = true;
              this.items = [];
              this.peticion = false;
              this.totalDesserts = 0;
              if (error.response) {
                if (error.response.status == 400) {
                  this.$store.commit(
                    "setError",
                    "Error en la peticion al servidor"
                  );
                }
              }
            });
        }else {
           
          this.$store.commit(
          "setError",
          "Debe ingresar al menos un parametro en formato correcto para realizar la busqueda"
        );
      }
      } 
    },
    limpiarBusqueda() {
        this.sociedad.nombre=null
        this.sociedad.departamento=null
        this.solicitud=false
        this.items=[]
        this.totalItemsSociedad=0
    },
    formatDate(date) {
      if (!date) return null;
      const [dates, hours] = date.split(" ");
      if (date.indexOf("-") > 0) {
        const [year, month, day] = dates.split("-");
        return `${day}/${month}/${year}`;
      }
      return date;
    }
  }
};
</script>